import React from "react";
import { FaTimes } from "react-icons/fa";
import { TiPlus, TiDivide, TiMinus, TiTimes } from "react-icons/ti";

const Operator = ({ operator }) => {
  switch (operator) {
    case "+":
      return <TiPlus />;

    case "-":
      return <TiMinus />;

    case "*":
      return <TiTimes />;

    case "/":
      return <TiDivide />;

    default:
      return <FaTimes />;
  }
};

export default Operator;
