import React from "react";
import styled from "styled-components";

const Input = ({ value, onChange }) => {
  const handleOnChange = ({ target: { value } }) => {
    if (value.length <= 0) {
      return onChange("");
    }

    if (["-", "+"].includes(value)) {
      return onChange(value);
    }

    if (value.length > 4 || value.search(/[-+]?[0-9]*[.,]?[0-9]+/) === -1)
      return;

    onChange(value);
  };

  const onBlur = ({ target: { value } }) => {
    if (value.length <= 0) return;

    if (["-", "+"].includes(value)) {
      return onChange("");
    }
    onChange(`${parseFloat(value)}`);
  };

  return (
    <StyledInput
      onChange={handleOnChange}
      onBlur={onBlur}
      value={value || ""}
    />
  );
};

export default Input;

const StyledInput = styled.input`
  height: 30px;
  font-size: 30px;
  width: 70px;
  text-align: end;
`;
