import React from "react";
import { useTranslation } from "react-i18next";
import { GiBrazilFlag, GiUsaFlag } from "react-icons/gi";
import Button from "../../styled/Button";
import styled from "styled-components";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  return (
    <StyledLanguageContainer>
      <StyledLanguageSelector>
        <span>{t("Language Select")}:</span>
        <LanguageSelectorButton onClick={() => i18n.changeLanguage("en")}>
          <GiUsaFlag />
        </LanguageSelectorButton>
        <LanguageSelectorButton onClick={() => i18n.changeLanguage("pt-BR")}>
          <GiBrazilFlag />
        </LanguageSelectorButton>
      </StyledLanguageSelector>
    </StyledLanguageContainer>
  );
};

const StyledLanguageContainer = styled.div`
  color: #9aff42;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  max-width: 560px;
`;

const StyledLanguageSelector = styled.div`
  color: #9aff42;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  max-width: 560px;
`;
const LanguageSelectorButton = styled(Button)`
  margin: 2px 10px;
  display: flex;
  padding: 2px;
`;

export default LanguageSelector;
