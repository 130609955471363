import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Button = styled(motion.button)`
  border-color: ${({ alert }) => (alert ? "red" : "#9aff42")};
  color: ${({ alert }) => (alert ? "red" : "#9aff42")};
  padding: 18px 18px 15px;
  margin: 20px;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  font-size: calc(14px + 2vmin);
  margin: 10px;
`;

export const WrapButton = (props) => (
  <Button {...props} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} />
);

export default WrapButton;
