import {
  ADD_OPERATIONS,
  SOLVE_OPERATION,
  RESTART_OPERATIONS,
} from "../actions/math";

const defaultSate = {
  operations: {},
  operationHistory: [],
};

const reducer = (state = defaultSate, action) => {
  const { operations } = state;

  switch (action.type) {
    case SOLVE_OPERATION:
      const { submittedValue, id } = action.payload;

      return {
        ...state,
        operations: {
          ...operations,
          [id]: { ...operations[id], submittedValue },
        },
      };

    case ADD_OPERATIONS:
      const { newOperations } = action.payload;

      return {
        ...state,
        operations: {
          ...operations,
          ...newOperations,
        },
      };

    case RESTART_OPERATIONS:
      return {
        ...state,
        operations: {},
        operationHistory: [...state.operationHistory, operations],
      };
    default:
      return state;
  }
};

export default reducer;
