import { SET_CURRENT_ENEMY, HIT_CURRENT_ENEMY } from "../actions/enemy";

const defaultSate = {
  enemyList: {
    1: { id: 1, level: 1, name: "Robô Bobby", hp: 5, loot: { money: 5 } },
    2: { id: 2, level: 2, name: "Small Robot", hp: 6, loot: { money: 7 } },
    3: { id: 3, level: 3, name: "Robô Médio", hp: 7, loot: { money: 10 } },
    4: {
      id: 4,
      level: 4,
      name: "Reprogrammed Robot",
      hp: 9,
      loot: { money: 12 },
    },
  },
  currentEnemy: null,
};

const reducer = (state = defaultSate, action) => {
  const { currentEnemy, enemyList } = state;
  const { payload } = action;
  const enemyListLength = Object.values(enemyList).length;

  switch (action.type) {
    case SET_CURRENT_ENEMY:
      const id = payload.id > enemyListLength ? enemyListLength : payload.id;

      const enemyChosen = enemyList[id];
      return {
        ...state,
        currentEnemy: { ...enemyChosen, maxHp: enemyChosen.hp },
      };

    case HIT_CURRENT_ENEMY:
      return {
        ...state,
        currentEnemy: { ...currentEnemy, hp: currentEnemy.hp - payload.number },
      };

    default:
      return state;
  }
};

export default reducer;
