export const getStoredMoney = () =>
  parseInt(localStorage.getItem("money") || "0");

export const setStoredMoney = async (number) => {
  localStorage.setItem("money", number);
};

export const getStoredHP = (startHP) =>
  parseInt(localStorage.getItem("hp") || startHP.toString());

export const setStoredHP = async (number) => {
  localStorage.setItem("hp", number);
};

export const getStoredMaxHP = (startMaxHP) =>
  parseInt(localStorage.getItem("maxHP") || startMaxHP.toString());

export const setStoredMaxHP = async (number) => {
  localStorage.setItem("maxHP", number);
};

export const getToken = () => localStorage.getItem("math-token");
export const setToken = (token) => localStorage.setItem("math-token", token);
