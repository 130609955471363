const defaultSate = {
  1: {
    id: 1,
    name: "Poção HP - pequena",
    description: "Restaura 2 de HP",
    price: 2,
    effect: 2,
    type: 1,
    icon: "GiHealthPotion",
  },
  2: {
    id: 2,
    name: "Poção HP - média",
    description: "Restaura 5 de HP",
    price: 4,
    effect: 5,
    type: 1,
    icon: "GiHealthPotion",
  },
  3: {
    id: 3,
    name: "HP MAX - pequena",
    description: "Aumenta 1 no máximo de HP",
    effect: 1,
    price: 12,
    type: 2,
    icon: "GiMicrochip",
  },
};

const reducer = (state = defaultSate, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
