import {
  HEAL_PLAYER,
  HIT_PLAYER,
  RESET_PLAYER,
  ADD_MONEY,
  LOAD_PLAYER,
  REMOVE_MONEY,
  PLAYER_ATTACK,
  SET_MAX_HP,
  SET_TOKEN,
} from "../actions/player";
import {
  getStoredMoney,
  setStoredMoney,
  getStoredHP,
  setStoredHP,
  getStoredMaxHP,
  setStoredMaxHP,
  getToken,
  setToken,
} from "../actions/helper";

const defaultSate = {
  name: "Player",
  level: 1,
  attacking: false,
  hp: getStoredHP(7),
  maxHp: getStoredMaxHP(7),
  money: getStoredMoney(),
  token: getToken(),
  items: [],
};

const reducer = (state = defaultSate, { payload, type }) => {
  let hp = 0;

  switch (type) {
    case RESET_PLAYER:
      return { ...state, hp: 7 };

    case ADD_MONEY:
      setStoredMoney(state.money + payload.number);
      return { ...state, money: state.money + payload.number };

    case SET_TOKEN:
      const { token } = payload;
      setToken(token);

      return { ...state, token };

    case REMOVE_MONEY:
      let money = state.money - payload.number;
      money = money <= 0 ? 0 : money;

      setStoredMoney(money);

      return {
        ...state,
        money,
      };

    case HEAL_PLAYER:
      hp = state.hp + payload.number;
      hp = hp > state.maxHp ? state.maxHp : hp;

      setStoredHP(hp);

      return { ...state, hp };

    case SET_MAX_HP:
      const maxHp = state.maxHp + payload.number;

      setStoredMaxHP(maxHp);

      return { ...state, maxHp };

    case HIT_PLAYER:
      hp = state.hp - payload.number;
      hp = hp <= 0 ? 0 : hp;

      setStoredHP(hp);

      return { ...state, hp };

    case LOAD_PLAYER:
      return { ...payload.player };

    case PLAYER_ATTACK:
      const { attacking } = payload;

      return { ...state, attacking };

    default:
      return state;
  }
};

export default reducer;
