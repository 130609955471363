import React from "react";
import CharacterWrapperCenter from "../../styled/CharacterWrapperCenter";
import { RiCoinsLine } from "react-icons/ri";
import { GoArrowSmallRight } from "react-icons/go";
import styled from "styled-components";
import { TiEquals } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { closeInfo } from "../../redux/actions/match";
import Button from "../../styled/Button";
import Operator from "../operator";
import { useTranslation } from "react-i18next";

const Info = ({ msg, gold }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useSelector(({ math: { operationHistory } }) =>
    Object.values(operationHistory[operationHistory.length - 1])
  );

  return (
    <CharacterWrapperCenter>
      <strong>{msg}</strong>
      <MoneyInfo>
        <MoneyText>{gold}</MoneyText>
        <RiCoinsLine />
      </MoneyInfo>
      <History>
        <HistoryTitle>{t("Your Answers")}:</HistoryTitle>
        {history
          .filter(({ submittedValue }) => ![null, ""].includes(submittedValue))
          .map(({ numbers, operators, result, submittedValue }, key) => (
            <HistoryItem key={key}>
              {numbers[0]}
              <Operator operator={operators[0]} />
              {numbers[1]}
              <TiEquals />
              <HistoryItemResult>
                <HistorySubmittedValue error={result !== submittedValue}>
                  {submittedValue}
                </HistorySubmittedValue>
                <HistoryItemCorrect>
                  {result !== submittedValue && (
                    <>
                      <GoArrowSmallRight /> {result}
                    </>
                  )}
                </HistoryItemCorrect>
              </HistoryItemResult>
            </HistoryItem>
          ))}
      </History>
      <Button onClick={() => dispatch(closeInfo())}>OK</Button>
    </CharacterWrapperCenter>
  );
};

export default Info;

const MoneyText = styled.div`
  margin: 0 10px;
`;

const HistoryTitle = styled.strong`
  margin: 10px 0;
`;

const History = styled.div`
  margin: 18px 0 28px;
  display: flex;
  flex-direction: column;
`;
const HistoryItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const HistoryItemResult = styled.div`
  display: flex;
  flex: 2;
`;

const HistoryItemCorrect = styled.div`
  color: #9aff42;
  text-decoration: none;
  align-items: center;
  display: flex;
`;
const HistorySubmittedValue = styled.div`
  color: ${({ error }) => (error ? "red" : "#9aff42")};
  text-decoration: ${({ error }) => error && "line-through"};
`;

const MoneyInfo = styled.div`
  display: flex;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  margin: 18px 20px;
  justify-content: center;
`;
