import React, { useEffect } from "react";
import CharacterWrapper from "../../styled/CharacterWrapper";
import Status from "../../styled/Status";
import styled from "styled-components";
import Hp from "../hp";
import { RiCoinsLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { endMatch, setInfo } from "../../redux/actions/match";
import { hitCurrentEnemy } from "../../redux/actions/enemy";
import { START, PREPARING } from "../../helper/MatchStatus";
import { useTranslation } from "react-i18next";
import {
  healPlayer,
  removeMoney,
  playerAttack,
} from "../../redux/actions/player";

const Enemy = (props) => {
  const { t } = useTranslation();
  const [
    { hp, money, maxHp, attacking },
    operations,
    status,
  ] = useSelector(({ player, math, match }) => [
    player,
    Object.values(math.operations),
    match.status,
  ]);
  const dispatch = useDispatch();
  const operationsLength = operations.filter(
    (operation) => operation.submittedValue === null
  ).length;

  useEffect(() => {
    if (hp <= 0) {
      dispatch(setInfo({ msg: t("You Lost. Try again!"), gold: "-5" }));
      dispatch(endMatch());
      dispatch(healPlayer(5));
      dispatch(removeMoney(5));
    }
  });

  useEffect(() => {
    if (attacking) {
      setTimeout(() => {
        dispatch(hitCurrentEnemy(1));
        dispatch(playerAttack(false));
      }, 70);
    }
  }, [attacking, dispatch]);

  return (
    <CharacterWrapper>
      <strong>{t("Player")}</strong>
      <Wrapper>
        <Status>
          <Hp hp={hp} maxHp={maxHp} />
        </Status>
        <Status>
          {[START, PREPARING].includes(status) && (
            <Hp type={2} hp={operationsLength} maxHp={10} />
          )}
        </Status>
        <Status>
          <RiCoinsLine /> {money}
        </Status>
      </Wrapper>
      {attacking && <AttackLaser />}
    </CharacterWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const AttackLaser = styled.div`
  position: fixed;
  border-left: 6px solid green;
  height: 70vh;
  bottom: 10%;
  transform: rotate(-14deg);
`;

export default Enemy;
