export const HIT_CURRENT_ENEMY = "HIT_CURRENT_ENEMY";
export const SET_CURRENT_ENEMY = "SET_CURRENT_ENEMY";

export const setCurrentEnemy = (id) => ({
  type: SET_CURRENT_ENEMY,
  payload: { id },
});

export const hitCurrentEnemy = (number) => ({
  type: HIT_CURRENT_ENEMY,
  payload: { number },
});
