import { restartOperations } from "./math";
import { setCurrentEnemy } from "./enemy";
import { putDeviceCount } from "../../api";

export const START_MATCH = "START_MATCH";
export const MATCH_PREPARATION = "MATCH_PREPARATION";
export const PRACTICE = "PRACTICE";
export const HIT_PLAYER = "HIT_PLAYER";
export const RESET_PLAYER = "RESET_PLAYER";
export const ADD_MONEY = "ADD_MONEY";
export const REMOVE_MONEY = "REMOVE_MONEY";
export const SET_LEVEL = "SET_LEVEL";
export const SET_INFO = "SET_INFO";
export const END_MATCH = "END_MATCH";

export const matchPreparation = () => ({
  type: MATCH_PREPARATION,
});

export const practice = () => ({
  type: PRACTICE,
});

export const getReadyMatch = () => (dispatch, getState) => {
  const {
    match: { level },
  } = getState();

  const enemyId = Math.floor(Math.random() * level === 0 ? 4 : level) + 1;

  dispatch(setCurrentEnemy(enemyId));
  dispatch(startMatch());
};

export const startMatch = (level) => ({
  type: START_MATCH,
  payload: { level },
});

export const resetPlayer = () => ({
  type: RESET_PLAYER,
});

export const setLevel = (level) => ({
  type: SET_LEVEL,
  payload: { level },
});

export const hitPlayer = (number) => ({
  type: HIT_PLAYER,
  payload: { number },
});

export const addMoney = (number) => ({
  type: ADD_MONEY,
  payload: { number },
});

export const removeMoney = (number) => ({
  type: REMOVE_MONEY,
  payload: { number },
});

export const endMatch = () => (dispatch, getState) => {
  const {
    player: { token },
  } = getState();

  token && putDeviceCount(token);

  dispatch(restartOperations());
  dispatch({ type: END_MATCH });
};

export const setInfo = (info) => (dispatch) => {
  dispatch({ type: SET_INFO, payload: { info } });
};

export const closeInfo = () => ({ type: SET_INFO, payload: { info: null } });
