import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const LanguageSelector = () => {
  const { t } = useTranslation();

  return (
    <StyledLanguageSelector>
      <CreatedBy>{t("Created by")}:</CreatedBy>
      <Link target="_blank" href="https://jon.maciel.dev">
        {t("Jon Maciel")}
      </Link>
    </StyledLanguageSelector>
  );
};

const StyledLanguageSelector = styled.div`
  color: #9aff42;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 5px;
  max-width: 520px;
  width: 100%;
  margin: 10px;
  font-size: calc(8px + 1vmin);
`;
const CreatedBy = styled.span`
  color: #9aff42;
  font-size: 16px;
  margin-right: 5px;
`;
const Link = styled.a`
  color: #9aff42;
  font-size: 18px;
`;

export default LanguageSelector;
