import React, { useEffect, useState } from "react";
import CharacterWrapper from "../../styled/CharacterWrapper";
import Button from "../../styled/Button";
import Operator from "../operator";
import { useSelector, useDispatch } from "react-redux";
import { TiEquals } from "react-icons/ti";
import { FaQuestion } from "react-icons/fa";
import LevelGenerator from "../../helper/LevelGenerator";
import styled from "styled-components";
import Reload from "./reload";
import { addOperations, solveOperation } from "../../redux/actions/math";
import { playerAttack } from "../../redux/actions/player";
import { useTranslation } from "react-i18next";

const Game = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [errorAlert, setErrorAlert] = useState(false);
  const [scale, setScale] = useState(1);
  const [operations, level] = useSelector(({ math, match }) => [
    Object.values(math.operations),
    match.level,
  ]);

  useEffect(() => {
    const levelGenerator = new LevelGenerator({ level, startId: 0 });
    dispatch(addOperations(levelGenerator.generateMatch()));
  }, [dispatch, level]);

  if (operations.length === 0) {
    return <div>Loading...</div>;
  }

  const currentOperation = operations.find(
    (operation) => operation.submittedValue === null
  );

  if (!currentOperation) {
    setTimeout(() => {
      const levelGenerator = new LevelGenerator({
        level,
        startId: operations.length,
      });
      dispatch(addOperations(levelGenerator.generateMatch()));
    }, 3500);

    return <Reload />;
  }

  const { numbers, operators, id, result, randomResults } = currentOperation;

  const onClick = (value) => {
    if (errorAlert) return false;

    if (result.toString() === value) {
      dispatch(playerAttack(true));
      dispatch(solveOperation(id, value));
    } else {
      setScale(0.9);
      setErrorAlert(true);
      setTimeout(() => setScale(1.1), 50);
      setTimeout(() => setScale(1.1), 100);
      setTimeout(() => setScale(0.9), 150);
      setTimeout(() => setScale(1), 200);

      setTimeout(() => {
        setErrorAlert(false);
        dispatch(solveOperation(id, value));
      }, 500);
    }
  };

  return (
    <CharacterWrapper animate={{ scale }}>
      <div style={{ color: errorAlert ? "red" : "" }}>
        {errorAlert ? `${t("Miss")}!` : t("Answer to Attack")}
      </div>
      <div>
        <div>
          {operators.map((operator, key) => (
            <OperationDisplay key={key} alert={errorAlert}>
              {key === 0 && numbers[key]}
              <Operator operator={operator} />
              {numbers[key + 1]}
              <TiEquals />
              <FaQuestion />
            </OperationDisplay>
          ))}
        </div>
        <div>
          {randomResults.map((value, key) => {
            return (
              <Button
                key={key}
                alert={errorAlert && result === value}
                onClick={() => onClick(value)}
              >
                {value}
              </Button>
            );
          })}
        </div>
      </div>
    </CharacterWrapper>
  );
};

export default Game;

const OperationDisplay = styled.div`
  text-width: 500;
  color: ${({ alert }) => (alert ? "red" : "#9aff42")};
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(14px + 3vmin);
`;
