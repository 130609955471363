import React from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 20px auto;
  border-color: red;
`;

const Reload = () => (
  <div style={{ color: "red" }}>
    <strong>Sem energia!</strong>
    <div>Recarregando....</div>
    <SyncLoader size={20} css={override} color="red" loading />
  </div>
);

export default Reload;
