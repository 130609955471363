export const PLAYER_ATTACK = "PLAYER_ATTACK";
export const HEAL_PLAYER = "HEAL_PLAYER";
export const HIT_PLAYER = "HIT_PLAYER";
export const RESET_PLAYER = "RESET_PLAYER";
export const ADD_MONEY = "ADD_MONEY";
export const LOAD_PLAYER = "LOAD_PLAYER";
export const REMOVE_MONEY = "REMOVE_MONEY";
export const SET_MAX_HP = "SET_MAX_HP";
export const SET_TOKEN = "SET_TOKEN";

export const resetPlayer = () => ({
  type: RESET_PLAYER,
});

export const hitPlayer = (number) => ({
  type: HIT_PLAYER,
  payload: { number },
});

export const addMoney = (number) => ({
  type: ADD_MONEY,
  payload: { number },
});

export const removeMoney = (number) => ({
  type: REMOVE_MONEY,
  payload: { number },
});

export const loadPlayer = (player) => ({
  type: LOAD_PLAYER,
  payload: { player },
});

export const healPlayer = (number) => ({
  type: HEAL_PLAYER,
  payload: { number },
});

export const setMaxHp = (number) => ({
  type: SET_MAX_HP,
  payload: { number },
});

export const playerAttack = (attacking) => ({
  type: PLAYER_ATTACK,
  payload: { attacking },
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: { token },
});
