import React, { useState } from "react";
import { useSelector } from "react-redux";
import MatchConfig from "../../components/match-config/";
import Game from "../../components/game";
import Enemy from "../../components/enemy";
import Info from "../../components/info";
import Practice from "../../containers/practice";
import PreMatch from "../../containers/pre-match";
import * as MatchStatus from "../../helper/MatchStatus";
import ItemShop from "../../components/item-shop";
import { AnimatePresence } from "framer-motion";

const Router = () => {
  const { status, info } = useSelector(({ match }) => match);
  const [displayShop, setDisplayShop] = useState(false);

  switch (status) {
    case MatchStatus.START:
      return (
        <>
          <Enemy />
          <Game />
        </>
      );

    case MatchStatus.PREPARING:
      return <PreMatch />;

    case MatchStatus.PRACTICE:
      return <Practice />;

    default:
      return info ? (
        <Info {...info} />
      ) : (
        <>
          <MatchConfig openShop={setDisplayShop} shoppingIsOpen={displayShop} />
          <AnimatePresence>{displayShop && <ItemShop />}</AnimatePresence>
        </>
      );
  }
};

export default Router;
