import React from "react";
import * as MatchStatus from "../../helper/MatchStatus";
import Button from "../../styled/Button";
import CharacterWrapper from "../../styled/CharacterWrapper";
import Operator from "../operator";
import { useDispatch, useSelector } from "react-redux";
import {
  matchPreparation,
  setLevel,
  practice,
} from "../../redux/actions/match";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { getMaxNumber, getOperators } from "../../helper/LevelGenerator";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../containers/language-selector";

const LevelCheck = ({ openShop, shoppingIsOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const level = useSelector(({ match }) => match.level);

  const startGame = (status) => {
    if (status === MatchStatus.START) {
      dispatch(matchPreparation());
    } else {
      dispatch(practice());
    }

    openShop(false);
  };

  return (
    <>
      <LanguageSelector />
      <CharacterWrapper>
        <Title>
          {t("Robots")} <FaTimes /> {t("Math")}!
        </Title>
        <div>{`${t("Level Select")}:`}</div>
        <SubCharacterWrapper>
          <Button onClick={() => dispatch(setLevel(-1))}>
            <IoMdArrowDropleft />
          </Button>
          <div>
            {level !== 0 && `${t("Level")}: `}
            {level === 0 ? t("Times Table") : level}
          </div>
          <Button onClick={() => dispatch(setLevel(1))}>
            <IoMdArrowDropright />
          </Button>
        </SubCharacterWrapper>
        <SubCharacterWrapper>
          <strong>{`${t("Numbers")}:`} </strong>
          <div>
            1 {t("to")} {getMaxNumber(level)}
          </div>
        </SubCharacterWrapper>
        <SubCharacterWrapper>
          <strong>{t("Operators")}:</strong>
          <div>
            {getOperators(level).map((operator, key) => (
              <Operator operator={operator} key={key} />
            ))}
          </div>
        </SubCharacterWrapper>
      </CharacterWrapper>
      <CharacterWrapperDescription>
        <div>
          <Button onClick={() => startGame(MatchStatus.START)}>
            {t("Battle")}
          </Button>
          <Button onClick={() => startGame(MatchStatus.PRACTICE)}>
            {t("Practice")}
          </Button>
          <Button onClick={() => openShop(!shoppingIsOpen)}>
            {!shoppingIsOpen ? (
              <span>{t("Shop")}</span>
            ) : (
              <span>{t("Close Shop")}</span>
            )}
          </Button>
        </div>
        {!shoppingIsOpen && (
          <GameDescription>
            <div>
              {t(
                "The robots want to end with math to stop the humanity evolution!"
              )}
            </div>
            <strong>{t("Use the math against them!")}</strong>
          </GameDescription>
        )}
      </CharacterWrapperDescription>
    </>
  );
};

export default LevelCheck;

const CharacterWrapperDescription = styled(CharacterWrapper)`
  border: 0;
  padding: 0;
  margin: 0;
`;

const Title = styled.strong`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
`;

const GameDescription = styled.div`
  font-size: 16px;
  font-size: calc(10px + 1vmin);
  margin-top: 14px;
`;

const SubCharacterWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 10px;
`;
