export const ADD_OPERATIONS = "ADD_OPERATIONS";
export const SOLVE_OPERATION = "SOLVE_OPERATION";
export const RESTART_OPERATIONS = "RESTART_OPERATIONS";

export const solveOperation = (id, submittedValue) => ({
  type: SOLVE_OPERATION,
  payload: { id, submittedValue },
});

export const addOperations = (newOperations) => ({
  type: ADD_OPERATIONS,
  payload: { newOperations },
});

export const restartOperations = () => ({
  type: RESTART_OPERATIONS,
});
