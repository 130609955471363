import React, { useState } from "react";
import styled from "styled-components";
import { GiHealthPotion, GiMicrochip } from "react-icons/gi";
import { RiCoinsLine } from "react-icons/ri";
import Button from "../../styled/Button";
import CharacterWrapper from "../../styled/CharacterWrapper";
import { useSelector, useDispatch } from "react-redux";
import { removeMoney, healPlayer, setMaxHp } from "../../redux/actions/player";
import { useTranslation } from "react-i18next";

const ItemShop = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const items = useSelector((state) => state.items);
  const { hp, maxHp, money } = useSelector((state) => state.player);
  const [bought, setBought] = useState([]);

  const buy = ({ id, price }) => {
    dispatch(removeMoney(price));
    setBought([...bought, id]);
    setTimeout(() => {
      setBought(bought.filter((itemId) => itemId !== id));
    }, 1000);
  };

  const handleOnClick = ({ id, price, effect, type }) => {
    if (type === 1) {
      if (hp < maxHp && money >= price) {
        dispatch(healPlayer(effect));
        buy({ id, price });
      }
    } else if (type === 2) {
      if (money >= price) {
        dispatch(setMaxHp(effect));
        buy({ id, price });
      }
    }
  };

  return (
    <CharacterWrapper
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0, duration: 0.07 }}
    >
      <strong>{t("Shop")}</strong>
      {Object.values(items).map((item, key) => {
        return !bought.includes(item.id) ? (
          <ItemContainerActive key={key} onClick={() => handleOnClick(item)}>
            <SubItemContainer>
              <ButtonActive onClick={() => handleOnClick(item)}>
                {item.type === 2 ? <GiMicrochip /> : <GiHealthPotion />}
              </ButtonActive>
              <ItemDescription>
                <ItemName>
                  {t(`item-type-${item.type}`)} -{" "}
                  {t(`item-size-${item.effect > 1 ? 2 : 1}`)}
                </ItemName>
                <div>
                  {t(`item-description-${item.type}`).replace(
                    "{effect}",
                    item.effect
                  )}
                </div>
              </ItemDescription>
            </SubItemContainer>
            <div>
              <RiCoinsLine /> {item.price}
            </div>
          </ItemContainerActive>
        ) : (
          <ItemContainer>
            <Info>{t("Bought it")}!</Info>
          </ItemContainer>
        );
      })}
    </CharacterWrapper>
  );
};

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  border: 0;
  margin: 4px 0;
  width: 100%;
  font-size: calc(8px + 1vmin);
  color: #9aff42;
  background-color: transparent;
  flex-direction: row;
  justify-content: space-between;
`;

const ItemContainerActive = styled(ItemContainer)`
  cursor: pointer;
`;

const ButtonActive = styled(Button)`
  cursor: pointer;
`;

const ItemDescription = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
`;

const ItemName = styled.strong`
  margin-bottom: 6px;
`;

const Info = styled.div`
  width: 100%;
  text-align: center;
  font-size: calc(14px + 1vmin);
`;

const SubItemContainer = styled.div`
  color: #9aff42;
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export default ItemShop;
