import React, { useMemo } from "react";
import { AiFillRobot } from "react-icons/ai";
import { FaRobot } from "react-icons/fa";
import { GiVintageRobot, GiMonoWheelRobot } from "react-icons/gi";

const Icon = ({ id }) => {
  switch (id) {
    case 1:
      return <AiFillRobot />;
    case 2:
      return <FaRobot />;
    case 3:
      return <GiVintageRobot />;
    case 4:
      return <GiMonoWheelRobot />;
    default:
      return <AiFillRobot />;
  }
};

export default ({ id }) => useMemo(() => <Icon id={id} />, [id]);
