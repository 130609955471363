import React, { useState, useMemo } from "react";
import Status from "../../styled/Status";
import { AiFillHeart } from "react-icons/ai";
import { BsBatteryFull, BsBatteryHalf, BsBattery } from "react-icons/bs";

const defaultColor = "#9aff42";
const delayTime = 500;

const Hp = ({ hp, maxHp, type = 1 }) => {
  const [currentHp, setCurrentHp] = useState(hp);
  const [color, setColor] = useState(defaultColor);
  const [fontWeight, setFontWeight] = useState(400);

  if (hp < currentHp) {
    setTimeout(() => setColor(defaultColor), delayTime);
    setColor("red");
    setCurrentHp(hp);
  }

  if (hp > currentHp) {
    setTimeout(() => setFontWeight(500), delayTime);
    setFontWeight(800);
    setCurrentHp(hp);
  }

  const Battery = () => {
    if (hp === 0) {
      return <BsBattery />;
    } else if (maxHp / 2 >= hp) {
      return <BsBatteryHalf />;
    } else {
      return <BsBatteryFull />;
    }
  };

  return (
    <Status style={{ color, fontWeight }}>
      {type === 1 ? <AiFillHeart /> : <Battery />} {currentHp} / {maxHp}
    </Status>
  );
};

export default ({ hp, maxHp, type }) =>
  useMemo(() => <Hp hp={hp} maxHp={maxHp} type={type} />, [hp, maxHp, type]);
