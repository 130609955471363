import styled from "styled-components";
import { motion } from "framer-motion";

const Wrapper = styled(motion.div)`
  align-items: center;
  background-color: #282c34;
  border-width: 2px;
  border-style: solid;
  border-color: #9aff42;
  border-radius: 10px;
  color: #9aff42;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px;
  min-height: 100px;
  padding: 22px;
  width: 90%;
  max-width: 500px;

  @media (max-width: 400px) {
    width: 90%;
  }
`;

export default Wrapper;
