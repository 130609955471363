import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Router from "./containers/router";
import Player from "./components/player";
import Footer from "./components/footer";
import { postDeviceCount } from "./api";
import { setToken } from "./redux/actions/player";
import * as MatchStatus from "./helper/MatchStatus";

import "./App.css";
import "./i18n";

const App = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(({ player }) => player);
  const { status } = useSelector(({ match }) => match);

  useEffect(() => {
    if (!token) {
      postDeviceCount().then(({ data }) => {
        dispatch(setToken(data.token));
      });
    }
  }, [dispatch, token]);

  return (
    <div className="App">
      <Router />
      <Player />
      {status === MatchStatus.WAITING && <Footer />}
    </div>
  );
};

export default App;
