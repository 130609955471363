import React, { useEffect } from "react";
import CharacterWrapper from "../../styled/CharacterWrapper";
import Button from "../../styled/Button";
import Operator from "../../components/operator";
import Input from "../../components/input";
import { useSelector, useDispatch } from "react-redux";
import { TiEquals } from "react-icons/ti";
import { endMatch, setInfo } from "../../redux/actions/match";
import { addMoney } from "../../redux/actions/player";
import LevelGenerator from "../../helper/LevelGenerator";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { addOperations, solveOperation } from "../../redux/actions/math";

const topFunction = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const Game = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [operations, level] = useSelector(({ math, match }) => [
    Object.values(math.operations),
    match.level,
  ]);

  useEffect(() => {
    topFunction();
    const levelGenerator = new LevelGenerator({ level, startId: 0 });
    dispatch(addOperations(levelGenerator.generateMatch()));
  }, [dispatch, level]);

  const submit = () => {
    const solvedOperationsCount = operations.reduce(
      (count, { result, submittedValue }) =>
        result === submittedValue ? count + 1 : count,
      0
    );
    const money =
      solvedOperationsCount === 0
        ? 0
        : level + Math.round(solvedOperationsCount / 3);

    dispatch(
      setInfo({
        msg: t("Keep Practicing"),
        gold: `+${money}`,
      })
    );
    dispatch(endMatch());
    dispatch(addMoney(money));
  };

  return (
    <CharacterWrapper>
      <h3>{t("Solve the exercise to practice!")}</h3>
      {operations.map(({ numbers, operators, id, submittedValue }, key) =>
        operators.map((operator, key) => (
          <OperationDisplay key={key}>
            {key === 0 && numbers[key]}
            <Operator operator={operator} />
            {numbers[key + 1]}
            <TiEquals />
            <Input
              onChange={(value) => dispatch(solveOperation(id, value))}
              value={submittedValue}
            />
          </OperationDisplay>
        ))
      )}
      <Button onClick={submit}>{t("Send")}</Button>
    </CharacterWrapper>
  );
};

export default Game;

const OperationDisplay = styled.div`
  text-width: 500;
  color: ${({ alert }) => (alert ? "red" : "#9aff42")};
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(14px + 3vmin);
`;
