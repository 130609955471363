import axios from "axios";

const URL = "https://math-robot-api.herokuapp.com";

export const postDeviceCount = () =>
  axios.post(`${URL}/devices`, {
    device: {
      match_count: 1,
    },
  });

export const putDeviceCount = (token) =>
  axios.put(`${URL}/devices/${token}`, {
    device: {
      match_count: 1,
    },
  });
